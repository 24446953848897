import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize, useIntlMessages } from '@kokolingo/localisation';
import { Link } from 'gatsby';

import { LogoCompany, Text } from '../../../components';

import attrs from '../../../constants/contacts/attributes';

import kokolingoLogo from '../../../images/new-landing/header-logo.svg';

import routes from '../../../routes';

import { SocialMedia } from '../../index';

import googlestore from '../../../images/google-play-badge.png';
import appstore from '../../../images/app-store-badge.svg';

import './index.scss';

const Footer = () => {
  const messages = useIntlMessages();
  return (
    <div className="footer-new">
      <Row className="footer-new-about" noGutters>
        <Col
          sm={{ offset: 1, span: 2 }}
          className="footer-new-logo d-sm-none d-md-block"
        >
          <img loading="lazy" src={kokolingoLogo} alt="" />
        </Col>
        <Col sm={3}>
          <Text as="h4" color="light" bold>
            <Localize id="footer.QuickLinks" />
          </Text>
          {process.env.GATSBY_LOCALE !== 'hr' && (
            <Link to={routes.EDUCATIONAL_CORNER}>
              <Text as="h5" color="light">
                <Localize id="footer.Blog" />
              </Text>
            </Link>
          )}
          <Link to={routes.FAQ}>
            <Text as="h5" color="light">
              <Localize id="footer.FAQ" />
            </Text>
          </Link>
          <Link to={routes.PRIVACY_POLICY}>
            <Text as="h5" color="light">
              <Localize id="footer.PrivacyPolicy" />
            </Text>
          </Link>
          {process.env.GATSBY_LOCALE === 'hr' && (
            <Link to={routes.PRIVACY_POLICY_MOBILE}>
              <Text as="h5" color="light">
                <Localize id="footer.PrivacyPolicyMobile" />
              </Text>
            </Link>
          )}
          <Link to={routes.TOS}>
            <Text as="h5" color="light">
              <Localize id="footer.TermsOfServices" />
            </Text>
          </Link>
          {process.env.GATSBY_LOCALE === 'hr' && (
            <Link to={routes.TOS_MOBILE}>
              <Text as="h5" color="light">
                <Localize id="footer.TermsOfServicesMobile" />
              </Text>
            </Link>
          )}
        </Col>
        <Col sm={3} className="footer-new-address">
          <Text as="h4" color="light" bold>
            <Localize id="footer.Contact" />
          </Text>
          <Text as="h5" color="light">
            <span dangerouslySetInnerHTML={{ __html: messages.footer.Mail }} />
          </Text>
          <Text as="h5" color="light">
            <span
              dangerouslySetInnerHTML={{ __html: messages.footer.Telephone }}
            />
          </Text>
          <Text as="h5" color="light">
            <span
              dangerouslySetInnerHTML={{ __html: messages.footer.Mobile }}
            />
          </Text>
          <Text as="h5" color="light">
            <Localize id="footer.Address" />
          </Text>
        </Col>
        <Col sm={2}>
          <SocialMedia />
        </Col>
      </Row>
      <div className="footer-new-copy">
        {process.env.GATSBY_LOCALE === 'hr' ? (
          <Row style={{ marginBottom: '10px' }} className="w-full">
            <Col
              xs={12}
              md={{ offset: 7, span: 5 }}
              className="flex flex-row gap-16 mt-4"
            >
              {/* Google Play Badge */}
              <a
                href={process.env.GATSBY_GOOGLE_PLAY_URL}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: '10px' }}
              >
                <img
                  src={googlestore}
                  alt="Dostupno na Google Play"
                  className="eglas-logo"
                />
              </a>

              {/* App Store Badge */}
              <a
                href={process.env.GATSBY_APP_STORE_URL}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: '10px' }}
              >
                <img
                  src={appstore}
                  alt="Dostupno na App Store"
                  className="eglas-logo"
                />
              </a>
            </Col>
          </Row>
        ) : null}

        <Row noGutters>
          <Col xs={12} md={{ offset: 2, span: 5 }}>
            <a href={attrs.company} rel="noopener noreferrer" target="_blank">
              <LogoCompany />
            </a>
            <Text size="small">
              <Localize id="footer.Copyright" />
            </Text>
          </Col>
          <Col xs={12} md={{ offset: 0, span: 4 }}>
            {process.env.GATSBY_LOCALE === 'hr' ? (
              <>
                <div className="eglas-logo" />
                <Text size="small">
                  Član društva: M. Vrankić, predsjednik uprave
                  <br />
                  Temeljni kapital: 2.654,46 eura unesen u cijelosti
                  <br />
                  Žiro račun: 2484008-1105169596, Raiffeisen bank d.d. Zagreb
                  <br />
                  IBAN HR4124840081105169596
                  <br />
                  SWIFT RZBHHR2X169596
                </Text>
              </>
            ) : (
              <Text size="small">
                <Localize id="footer.Copyright" />
              </Text>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
