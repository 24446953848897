const PREMIUM = [
  {
    available: true,
    descriptionId: 'pricing.Statistics',
  },
];

const SMART = [
  {
    available: false,
    descriptionId: 'pricing.Statistics',
  },
];

const MINI = [
  {
    available: false,
    descriptionId: 'pricing.Statistics',
  },
];

export default { PREMIUM, SMART, MINI };
