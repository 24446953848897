import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ModalPromo, ModalRegister } from '../../fragments';
import modalTypes from '../../constants/modalTypes';
import { time } from '../../utils';

const ModalContext = React.createContext(undefined);

const ModalProvider = ({ children }) => {
  const [modalData, setModalData] = useState({
    type: modalTypes.NONE,
    props: {},
  });

  const MODALS = {
    [modalTypes.PROMO]: <ModalPromo />,
    [modalTypes.REGISTER]: <ModalRegister {...modalData.props} />,
  };

  const getPackageLabel = useCallback(() => {
    const subscriptionKeyParam = window.location.hash.split('-')[1];

    return subscriptionKeyParam
      ? subscriptionKeyParam.toUpperCase()
      : undefined;
  }, []);

  const getShouldOpenPromoModal = useCallback(() => {
    const isPromoDate = time.getIsPromoDate();

    return isPromoDate;
  }, []);

  const openPromoModal = useCallback(() => {
    if (getPackageLabel()) {
      return;
    }

    setTimeout(() => {
      setModalData({ type: modalTypes.PROMO, props: {} });
    }, 3000);
  }, [getPackageLabel]);

  const openRegisterModal = useCallback(() => {
    if (!getPackageLabel()) {
      return;
    }

    setTimeout(() => {
      setModalData({
        type: modalTypes.REGISTER,
        props: {
          subscriptionKey: getPackageLabel(),
          showPackageLabel: true,
        },
      });
    }, 500);
  }, [getPackageLabel]);

  useEffect(() => {
    const shouldOpenPromoModal = getShouldOpenPromoModal();

    if (shouldOpenPromoModal) {
      openPromoModal();
    }
  }, [getShouldOpenPromoModal, openPromoModal]);

  useEffect(() => {
    openRegisterModal();
  }, [openRegisterModal]);

  useEffect(() => {
    function hashChange() {
      openRegisterModal();
    }

    window.addEventListener('hashchange', hashChange);

    return () => {
      window.removeEventListener('hashchange', hashChange);
    };
  }, [openRegisterModal]);

  return (
    <ModalContext.Provider value={{ modalData, setModalData }}>
      {modalData.type && MODALS[modalData.type]}
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ModalProvider, ModalContext };
